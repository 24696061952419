import { Button, CounterBadge, makeStyles } from '@fluentui/react-components';
import { Alert28Regular } from '@fluentui/react-icons';

const useStyles = makeStyles({
	button: {
		position: 'relative',
	},
	badge: {
		position: 'absolute',
		right: '30px',
		top: '0px',
	},
});

const Notification = () => {
	const classes = useStyles();
	return (
		<Button
			size="large"
			icon={<Alert28Regular />}
			appearance="transparent"
			className={classes.button}
		>
			<CounterBadge
				count={15}
				color="danger"
				size="medium"
				appearance="filled"
				className={classes.badge}
			/>
		</Button>
	);
};

export default Notification;
