import { Divider, FluentProvider, IdPrefixProvider, makeStyles, tokens } from '@fluentui/react-components';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import Notification from './components/Notification';
import Profile from './components/Profile';
import i18n from './i18n';
import { useTheme } from './theme/theme';

const theme = useTheme();
const useStyles = makeStyles({
	container: {
		minHeight: '80px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
        borderBottom: `1px solid ${tokens.colorNeutralStroke2}`,
	},

	right:{
		display: 'flex',
		flexDirection: 'row',
	}
});

export default function Root(props) {
	const styles = useStyles();
	const { keycloak } = props;

	return (
		<IdPrefixProvider value="cc-header">
			<I18nextProvider i18n={i18n}>				
				<FluentProvider theme={theme.lightTheme} className={styles.container}>
					<div className={styles.right}>
						<Notification />
						<Divider vertical={true} />
						<Profile keycloak={keycloak} />
					</div>
				</FluentProvider>
			</I18nextProvider>
		</IdPrefixProvider>
	);
}
