import { Avatar, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { Edit20Regular } from '@fluentui/react-icons';

const Profile = ({keycloak}) => {
	
	const token = keycloak.tokenParsed;

	if(!token)
		return null;

	const name = `${token.given_name} ${token.family_name}`

	const handleLogout = () => {
		keycloak.logout();
	};

	return (
		<div style={{ padding: '0 20px', display: 'flex' }}>
			
			<Menu>
				<MenuTrigger disableButtonEnhancement>
					<Avatar size={40} active="active" name={name} badge={{ status: "available" }} />
				</MenuTrigger>

				<MenuPopover>
					<MenuList>
						<MenuItem icon={<Edit20Regular />} onClick={handleLogout}>Sair </MenuItem>
					</MenuList>
				</MenuPopover>
			</Menu>
		</div>
	);
};

export default Profile;
